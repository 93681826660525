.email-form-section {
  height: auto;
  padding: 6rem 2rem;
  text-align: center;
  background: #f1f1f1;
}

.email-form-section.animate {
  opacity: 1;
  transform: translateY(0);
}

.email-form-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #121212;
  margin-bottom: 2rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1s ease-in-out;
}

.email-form-section h1,
.form-wrap{
  opacity: 0; /* 초기 상태 설정 */
  transform: translateY(200px); /* 초기 상태 설정 */
  transition: opacity 1s ease-out, transform 1s ease-out; /* 애니메이션 */
}

.email-form-section h1.visible,
.form-wrap.visible {
  opacity: 1;
  transform: translateY(0);
}

.email-form-section h1.visible::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  height: 10px;
  background-color: #00c3ff;
  width: 0;
  z-index: -1;
  animation: underline 1s ease forwards;
  animation-delay: 1s;
}

.form-wrap {
    width: 1080px;
    margin: 0 auto;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-top: 4px solid #00c3ff;
  animation: fadeInUp 1s ease-in-out;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.form-group label {
  font-size: 1.2rem;
  color: #333;
  margin-right: 1rem;
  flex: 0 0 20%; /* 라벨의 넓이 고정 */
  text-align: left;
}

.form-group input,
.form-group textarea {
  flex: 1;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue",
    "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
}

.email-form button {
  display: block;
  width: 100%;
  background-color: #00c3ff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-form button:hover {
  background-color: #009fcc;
}

.status {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #333;
}

/* 반응형 디자인 추가 */
@media (max-width: 768px) {
  .email-form-section {
    padding: 4rem 1rem;
  }

  .form-wrap {
    width: 100%;
    padding: 0 1rem;
  }

  .email-form {
    padding: 1.5rem;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group label {
    margin-bottom: 0.5rem;
    flex: 0 0 auto;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    font-size: 1rem;
    padding: 0.6rem;
    border-radius: 8px;
  }

  .email-form button {
    width: 100%;
    border-radius: 8px;
  }

  .email-form-section h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .email-form-section {
    padding: 3rem 0.5rem;
  }

  .form-wrap {
    padding: 0 0.5rem;
  }

  .email-form {
    padding: 1rem;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.875rem;
  }

  .email-form button {
    font-size: 1rem;
  }

  .email-form-section h1 {
    font-size: 1.75rem;
  }
}