.services-section {
  height: auto;
  padding: 6rem 2rem; /* 위아래로 더 넓게 설정 */
  background: #f1f1f1;
  text-align: center;
}

.services-section h1,
.service {
  opacity: 0; /* 초기 상태 설정 */
  transform: translateY(200px); /* 초기 상태 설정 */
  transition: opacity 1s ease-out, transform 1s ease-out; /* 애니메이션 */
}

.services-section h1.visible,
.service.visible {
  opacity: 1;
  transform: translateY(0);
}

.services-section h1.visible::before {
  animation: underline 1s ease forwards;
  animation-delay: 1s; /* 시간차 설정 */
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 1080px;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  transition: transform 1s ease, box-shadow 1s ease;
  text-align: left; /* 왼쪽 정렬 */
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.services-section h1 {
  font-size: 2.5rem; /* 글꼴 크기 키우기 */
  font-weight: 700; /* 글꼴 굵게 설정 */
  color: #121212;
  margin-bottom: 2rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1; /* 텍스트를 앞에 위치하게 설정 */
}

.services-section h1::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px; /* 텍스트 아래에 위치 */
  height: 10px; /* 밑줄 두께 설정 */
  background-color: #00c3ff; /* 파란색 포인트 컬러 */
  width: 0;
  z-index: -1; /* 밑줄을 텍스트 뒤에 위치하게 설정 */
  transition: width 1s ease;
}

.service h3 {
  font-size: 1.75rem;
  font-weight: 900;
  color: #00c3ff; /* 파란색 포인트 컬러 */
  margin-bottom: 1rem;
  width: 480px;
  padding: 3rem;
}

.service p {
  font-size: 1rem;
  color: #333;
}

.service ul {
  list-style-type: none;
  padding: 5px;
  width: 600px;
}

.service ul li {
  margin: 0.75rem 0; /* 마진을 좀 더 크게 설정 */
  font-size: 1.1rem; /* 글씨 크기 키우기 */
  color: #333;
}

.service ul li::before {
  content: "•";
  color: #00c3ff; /* 파란색 포인트 컬러 */
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}

/* 반응형 미디어 쿼리 */
@media (max-width: 768px) {
  .services-section {
    padding: 3rem 1rem;
  }

  .services-section h1 {
    font-size: 2rem;
  }

  .services-container {
    width: 100%;
    padding: 0;
  }

  .service {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
  }

  .service h3 {
    width: 100%;
    padding: 0;
    margin: 1rem;
    font-size: 1.5rem;
  }

  .service ul {
    width: 100%;
    padding: 0;
  }

  .service ul li {
    font-size: .9rem;
  }

  .service ul li::before {
    content: "•";
    color: #00c3ff; /* 파란색 포인트 컬러 */
    font-weight: bold;
    display: inline-block;
    width: 1rem;
    margin-left: 1rem;
  }
}
