.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  text-align: left;
  width: 100%;
  height: 400px; /* 높이 조정 */
  box-sizing: border-box;
  display: flex;
  align-items: flex-end; /* 컨텐츠를 하단에 배치 */
}

.footer-container {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
}

.footer-info {
  margin: 10px 0;
  font-size: 1.2rem;
}

.footer-info div {
  margin-bottom: 10px;
  display: flex; /* 수평 정렬 */
  flex-wrap: wrap; /* 화면 크기가 작아질 때 줄바꿈 */
}

.footer-info div:last-child {
  margin-bottom: 30px;
}

.footer-info span {
  margin-right: 15px;
  white-space: nowrap;
  position: relative;
}

.footer-info span:last-child {
  margin: 0; /* 마지막 요소의 오른쪽 마진 제거 */
}

.footer-info span:not(:last-child)::after {
  content: "|";
  margin-left: 15px; /* 간격을 margin-left로 조정 */
  color: #fff;
}

.footer-info a {
  color: #fff;
  text-decoration: none; /* 링크 밑줄 제거 */
  margin-left: 5px; /* "사업자정보확인" 링크와 텍스트 사이 간격 조정 */
}

.footer-info a:hover {
  color: #00c3ff;
  cursor: pointer;
}

.footer-legal {
  text-align: center;
  margin: 20px 0;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  width: 100%;
}

.footer-legal p {
  margin: 5px 0;
  font-size: 1rem;
  color: #f0f0f0;
}

/* 반응형 디자인 추가 */
@media (max-width: 768px) {
  .footer-container {
    margin-top: 5rem;
    padding: 1rem;
  }
  .footer {
    align-items: center; /* 컨텐츠를 가운데 정렬 */
    text-align: center; /* 텍스트 가운데 정렬 */
    padding: 20px 10px;
  }

  .footer-info {
    text-align: center; /* 텍스트 가운데 정렬 */
    font-size: 1rem;
  }

  .footer-info div {
    flex-direction: column; /* 세로 정렬 */
    align-items: start;
  }

  .footer-info span {
    margin: 5px 0; /* 요소 간의 간격 조정 */
  }

  .footer-info span:not(:last-child)::after {
    content: none; /* 구분자 제거 */
  }

  .footer-legal {
    text-align: center; /* 텍스트 가운데 정렬 */
    margin-top: 10px;
    border-top: 1px solid #ddd; /* 상단 경계선 유지 */
  }
  .footer-legal p {
    font-size: 0.8rem; /* 폰트 크기 조정 */
  }
}

@media (max-width: 480px) {
  .footer-info {
    font-size: 0.875rem; /* 폰트 크기 조정 */
  }

  .footer-legal p {
    font-size: 0.7rem; /* 폰트 크기 조정 */
  }
}
