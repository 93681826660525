/* Reviews.css */
.reviews-section {
  height: auto;
  padding: 6rem 2rem;
  text-align: center;
  background: #f9f9f9;
}

.reviews-section h1,
.review {
  opacity: 0; /* 초기 상태 설정 */
  transform: translateY(200px); /* 초기 상태 설정 */
  transition: opacity 1s ease-out, transform 1s ease-out; /* 애니메이션 */
}

.reviews-section h1.visible,
.review.visible {
  opacity: 1;
  transform: translateY(0);
}

.reviews-section h1.visible::before {
  animation: underline 1s ease forwards;
  animation-delay: 1s; /* 시간차 설정 */
}

.reviews-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.review {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 320px;
  border-top: 4px solid #00c3ff; /* 파란색 포인트 컬러 */
  transition: transform 1s ease, box-shadow 1s ease;
  text-align: left; /* 왼쪽 정렬 */
  padding-bottom: 4rem;
}

.review:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.reviews-section h1 {
  font-size: 2.5rem; /* 글꼴 크기 키우기 */
  font-weight: 700; /* 글꼴 굵게 설정 */
  color: #121212;
  margin-bottom: 2rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1; /* 텍스트를 앞에 위치하게 설정 */
}

.reviews-section h1::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px; /* 텍스트 아래에 위치 */
  height: 10px; /* 밑줄 두께 설정 */
  background-color: #00c3ff; /* 파란색 포인트 컬러 */
  width: 0;
  z-index: -1; /* 밑줄을 텍스트 뒤에 위치하게 설정 */
  transition: width 1s ease;
}

.review h3 {
  font-size: 1.75rem;
  font-weight: 900;
  color: #00c3ff; /* 파란색 포인트 컬러 */
  margin-bottom: 2rem;
}

.review p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem; /* 간격 추가 */
}

.review p:last-of-type {
  font-weight: 700; /* 이름을 굵게 */
  margin-bottom: 2rem; /* 이름과 평점 사이 간격 */
}

.review span {
  font-size: 1.5rem;
  color: #ffa500; /* 평점에 주황색 적용 */
  display: block; /* 블록 요소로 변경하여 새로운 줄로 이동 */
  margin-top: 0.5rem; /* 평점과 이름 사이 간격 */
}

@media (max-width: 768px) {
  .reviews-section {
    padding: 3rem 0.5rem;
  }

  .reviews-container {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }

  .review {
    max-width: 100%;
    padding: 1rem;
  }

  /* .review {
    padding: 1rem;
  } */

  .reviews-section h1 {
    font-size: 1.75rem;
  }

  .review h3 {
    font-size: 1.25rem;
  }

  .review p {
    font-size: 1rem;
  }

  .review span {
    font-size: 1rem;
  }
}