@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px); /* 효과를 더 크게 설정 */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes highlight {
  from {
    background-size: 0% 100%;
  }
  to {
    background-size: 100% 100%;
  }
}

.App {
  text-align: center;
}

.animate {
  animation: fadeInUp 1s ease-out both;
}
