@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.clients-section {
  height: auto;
  padding: 6rem 2rem; /* 위아래로 더 넓게 설정 */
  text-align: center;
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.clients-section h1 {
  opacity: 0;
  transform: translateY(50px); /* 초기 상태 설정: 조금만 내려가도록 수정 */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.clients-section h1.visible {
  opacity: 1;
  transform: translateY(0);
}

.clients-section h1.visible::before {
  animation: underline 1s ease forwards;
  animation-delay: 1s;
}

.clients-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 800px;
  position: relative;
  align-items: center;
}

.client {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-image {
  max-width: 100%;
  height: auto;
}

.clients-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #121212;
  margin-bottom: 2rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1;
  opacity: 0; /* 초기 상태 설정 */
  transform: translateY(200px); /* 초기 상태 설정 */
  transition: opacity 1s ease-out, transform 1s ease-out; /* 애니메이션 */
}

.clients-section h1.visible {
  opacity: 1;
  transform: translateY(0);
}

.clients-section h1.visible::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px; /* 텍스트 아래에 위치 */
  height: 10px; /* 밑줄 두께 설정 */
  background-color: #00c3ff; /* 파란색 포인트 컬러 */
  width: 0;
  z-index: -1; /* 밑줄을 텍스트 뒤에 위치하게 설정 */
  transition: width 1s ease;
}

.slick-slider {
  width: 100%;
  height: 70px;
}

/* 반응형 미디어 쿼리 */
@media (max-width: 768px) {
  .clients-section {
    width: 100%;
    padding: 3rem 1rem;
    flex-direction: column; /* 기본적으로 컬럼 방향 설정 */
  }

  .clients-section h1 {
    font-size: 2rem;
  }

  .clients-section h1.visible {
    width: 110px;
    margin: 0px auto;
    margin-bottom: 40px;
  }

  .clients-container {
    width: 100%;
  }

  .client {
    flex: 0 0 100%;
  }

  .client-image {
    max-width: 80%;
  }
}
