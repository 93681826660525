header {
  padding: 2rem;
  transition: all 0.1s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent; /* 초기 상태에서 배경색 투명 */
}

nav {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: #fff;
}

.menu {
  display: flex;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  font-size: 1.2rem;
  text-decoration: none;
  color: #fff;
  padding: 10px 20px;
  display: block;
}

nav ul li a:hover,
.header-sticky nav ul li a:hover {
  color: #00c3ff;
}

/* Sticky header styles */
.header-sticky {
  background-color: #fff;
}

.header-sticky nav ul li a,
.header-sticky nav .logo {
  color: #333;
}

.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
}

.header-sticky .hamburger {
  color: #333;
}

.menu.open {
  transform: translateX(0);
}

/* Main section styles */
.main-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.main-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  position: absolute;
}

.main-section div {
  color: white;
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  font-weight: 700;
  animation: fadeInUp 1s ease-in-out;
}

.main-section .highlight {
  display: inline-block;
  background: linear-gradient(to right, rgba(0, 195, 255, 0.7) 0%, rgba(0, 195, 255, 0.7) 100%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  animation: highlight 1s ease forwards;
  animation-delay: 1.1s;
}

@media (max-width: 768px) {
  .main-section div {
    font-size: 2rem;
  }

  .menu {
    display: flex;
    flex-direction: column;
    background-color: #333;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding-top: 60px;
  }

  .menu.open {
    transform: translateX(0);
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 20px 0;
  }

  .hamburger {
    display: block;
  }

  .header-sticky nav ul li a,
  .header-sticky nav .logo {
    color: #fff;
  }
}

@media (max-width: 480px) {
  .main-section div {
    font-size: 1.5rem;
  }
}
